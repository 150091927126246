<template>
  <div class="about-container">
    <!-- 头部 -->
    <div class="header">
      <div class="hy">
        <h1 class="hylogo" :title="showHeadTitle" v-if="store.state.roleList">
          <span class="title-fs" v-if="store.state.roleList.length < 2">
            {{ showHeadTitle ? showHeadTitle : title }}
          </span>

          <template v-else>
            <el-button size="small" class="toggleBtn"
              ><el-icon class="toggleIcon"><Switch /></el-icon>切换</el-button
            >
            <el-select
              :model-value="userValue"
              @change="onChangeRole"
              class="m-2 titleSelect"
              size="large"

            >
              <template #prefix>
                {{ ( store.state.roleList.find(item => item.code === userValue)|| {}).name }}
              </template>
              <el-option
                v-for="item in store.state.roleList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              />
            </el-select>
          </template>
        </h1>
        <div class="btn-box">
          <img :src="editUrl" alt="修改密码" title="修改密码" @click="editPwd" />
          <img :src="outUrl" alt="登出系统" title="登出系统" @click="logOut" />
        </div>
      </div>
    </div>
    <!-- 修改密码弹框组件 -->
    <EditPwd :dialogPwdVisible="dialogPwdVisible" @closePwd="closePwd"></EditPwd>

    <!-- 轮播图 -->
    <div class="block">
      <el-carousel indicator-position="none">
        <el-carousel-item>
          <el-image :src="urlone"></el-image>
        </el-carousel-item>
        <el-carousel-item>
          <el-image :src="urltwo"></el-image>
        </el-carousel-item>
        <el-carousel-item>
          <el-image :src="urlone"></el-image>
        </el-carousel-item>
        <el-carousel-item>
          <el-image :src="urltwo"></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 系统选择模块 -->
    <div class="main">
      <p class="info">
        ◆&nbsp;
        <span class="roleName">{{ roleName }}</span>
        {{
          sysLength == 0
            ? "暂无可访问的应用系统"
            : `老师 , 欢迎您使用${title}, 请选择应用系统`
        }}&nbsp;◆
      </p>
      <!-- 系统选择项 -->
      <div class="card-carousel" v-if="cardList && cardList.length">
        <el-carousel
          height="160px"
          trigger="click"
          :autoplay="false"
          indicator-position="none"
          :arrow="cardList.length > 1 ? 'always' : 'never'"
        >
          <el-carousel-item v-for="(num, id) in cardList.length" :key="num">
            <div class="card-container">
              <div
                class="item-box"
                @click="goSys(item)"
                v-for="(item, index) in cardList[id]"
                :key="item.sysCode"
                :data-index="item.sysCode"
              >
                <el-image :src="item.imagePath + '-1.png'" fit="contain"></el-image>
                <h4 class="title">{{ item.displayName }}</h4>
                <span class="num-sort"
                  >{{ cardList[0].length * id + (index + 1) >= 10 ? "" : 0
                  }}{{ cardList[0].length * id + (index + 1) }}</span
                >
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import axios from "axios";
import * as ENVS from "@/env";
import router from "@/router";
import Cookies from "js-cookie";
import EditPwd from "@/components/EditPwd";

import { toggleRole } from "@/api/user";
import {removePwd, getToken, validateToken, removeAuth} from "@/utils/auth";
import { Switch } from "@element-plus/icons";
import { ElLoading, ElMessage, ElMessageBox } from "element-plus";
import {
  reactive,
  toRefs,
  onMounted,
  nextTick,
  watch,
  computed,
  onBeforeMount,
} from "vue";
import store from "@/store";

export default {
  components: { Switch, EditPwd },
  setup() {
    // 使用vuex
    const store = useStore();
    const title = ENVS.TITLE;
    const state = reactive({
      urlone: require("@/assets/home-images/banner01.jpg"),
      urltwo: require("@/assets/home-images/banner02.jpg"),
      editUrl: require("@/assets/home-images/xgmm.png"),
      outUrl: require("@/assets/home-images/tc.png"),
      dialogPwdVisible: false,
      sysList: [],
      cardList: [],
      roleName: "",
      account: "",
      sysLength: "",
      // 隐藏头部
      showHead: false,
      userValue: "",
    });

    // 子系统鼠标事件
    nextTick(() => {
      if (document.querySelectorAll(".card-container .item-box")) {
        let domList = document.querySelectorAll(".card-container .item-box");
        for (let i = 0; i < domList.length; i++) {
          domList[i].onmouseover = function () {
            if (domList[i].querySelector(".el-image img")) {
              for (let j = 0; j < state.sysList.length; j++) {
                if (state.sysList[j].sysCode == domList[i].getAttribute("data-index")) {
                  domList[i].querySelector(".el-image img").src =
                    state.sysList[j].imagePath + "-2.png";
                }
              }
              domList[i].querySelector("h4.title").classList.add("hover-sty");
            }
          };

          domList[i].onmouseout = function () {
            if (domList[i].querySelector(".el-image img")) {
              for (let j = 0; j < state.sysList.length; j++) {
                if (state.sysList[j].sysCode == domList[i].getAttribute("data-index")) {
                  domList[i].querySelector(".el-image img").src =
                    state.sysList[j].imagePath + "-1.png";
                }
              }
              domList[i].querySelector("h4.title").classList.remove("hover-sty");
            }
          };
        }
      }
    });


    const isValid = async ()=>{
      return new Promise((resolve, reject)=>{
        validateToken(getToken())
            .then(() => {
              resolve();
            })
            .catch(() => {
              ElMessageBox.alert('登录过期，请重新登录', '超时提醒', {
                confirmButtonText: '确定',
                callback: () => {
                  removeAuth();
                  router.push('/login')
                },
              })
            });

      })
    }

    // 系统跳转
    const goSys = async (item) => {
      await isValid();
      //后端 区分校 学生参数
      var code = store.state.loginInfo.platformCode;
      if (item.accessMode == 1) {
        state.account = store.state.loginInfo.userInfo.account;
        window.open(item.accessInfo, `${item.accessInfo}-${item.accessMode}`);
      } else if (item.accessMode == 2) {
        state.account = store.state.loginInfo.userInfo.account;
        let url = item.accessInfo;

        if (url.indexOf("?") == -1) {
          url += `?platformCode=${code}&ac=${state.account}&tk=${getToken()}&sc=${item.sysCode}&ba=${store.state.loginInfo.ba}`;
        } else {
          url += `&platformCode=${code}&ac=${state.account}&tk=${getToken()}&sc=${item.sysCode}&ba=${store.state.loginInfo.ba}`;
        }

        window.open(
          // http://192.168.0.101:8080/#/ssoSys
          url,
          `${item.accessInfo}-${item.sysCode}`
        );
      } else if (item.accessMode == 3) {
        // 加载中loading优化 避免页面不反应 用户多次点击
        const loading = ElLoading.service({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        // 3: 请求跳转
        axios
          .post(`${ENVS.BASEURL}${item.accessInfo}`, {
            account: state.account,
            sysCode: item.sysCode,
          })
          .then((result) => {
            // console.log(result);
            if (result.data.code == 0) {
              // code 0 跳转
              window.open(
                result.data.data.url,
                `${result.data.data.url}-${item.sysCode}`
              );
            } else {
              // 否则 跳转404
              const { href } = router.resolve({
                path: "/404",
              });
              window.open(href, "_blank");
            }
            setTimeout(() => loading.close(), 200);
          });
      }
    };

    // 修改密码
    const editPwd = async () => {
      await isValid();
      state.dialogPwdVisible = true;
    };
    // 关闭密码弹框自定义事件
    const closePwd = () => {
      state.dialogPwdVisible = false;
    };

    // 退出
    const logOut = () => {
      ElMessageBox.confirm("您即将登出系统, 请确认是否执行该操作? ", "退出系统", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          store.commit("removeSsoToken");
          store.commit("removeLoginInfo");
          store.commit("removeCertSso");

          removePwd();
          if (Object.keys(Cookies.get()).length) {
            for (let k in Cookies.get()) {
              if (k == "sso-user") {
                continue;
              }
              Cookies.remove(k);
            }
          }
          router.push("/login");
          ElMessage.success("登出成功");
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "已取消登出操作",
          });
        });
    };

    const onChangeRole = (params) => {
      toggleRole({
        code: params,
        ssoToken: store.state.ssoToken,
      }).then((res) => {
        if (res.data && res.data.data && res.data.data.isToggle ) {
          state.userValue = params;
          localStorage.setItem("sso-current-user", params);
        }
      }).catch(()=>{
        //失败取第一个
        state.userValue = store.state.roleList[0].code
      })
    };

    watch(
      () => state.userValue,
      (val) => {
        if (val) {
          let current = store.state.roleList.reduce((prev, current) => {
            if (current.code === val) {
              prev = current;
            }

            return prev;
          }, "");
          store.commit("setCurrentRole", current);

          state.sysLength = current.subSystem.length;
          state.sysList = current.subSystem;
          state.cardList = [];

          nextTick(() => {
            if (state.sysLength.length <= 4) {
              state.cardList.push(current.subSystem);
            } else {
              state.cardList = group(current.subSystem, 4);
            }
          });
        }
      },
      {
        immediate: true,
      }
    );

    // 切割数组 渲染轮播条数 第一个参数是需要拆分的原数组，第二个是拆分的每个数组包含多少个元素
    function group(array, subGroupLength) {
      let index = 0;
      let newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, (index += subGroupLength)));
      }
      return newArray;
    }

    let showHeadTitle = computed(() => {
      return store.getters.currentUser ? store.getters.currentUser.name : "";
    });

    onBeforeMount(() => {
      store.commit("setRoleInfo", store.state.loginInfo);
    });

    onMounted(() => {
      if (store.state.loginInfo) {
        // 如果vuex中 有登录信息 获取出来渲染系统列表
        //
        watch(
          () => store.state.roleList,
          (val) => {
            if (val && val.length) {
              var activeRole = val.filter((item) => {
                return item.active;
              });

              var prevValue = parseInt(localStorage.getItem("sso-current-user"));
              var length = 0;
              if (prevValue) {
                length = val.filter((item) => {
                  return item.code === prevValue;
                }).length;
              }

              state.userValue = length
                ? prevValue
                : activeRole.length
                ? activeRole[0].code
                : val[0].code;
            }
          },
          {
            immediate: true,
          }
        );

        watch(()=> store.state.ssoToken, (val)=>{
          if(val){
            if( store.state.roleList &&  store.state.roleList.length){
              var activeRole = store.state.roleList.filter((item) => {
                return item.active;
              });

              var prevValue = parseInt(localStorage.getItem("sso-current-user"));
              var length = 0;
              if (prevValue) {
                length = store.state.roleList.filter((item) => {
                  return item.code === prevValue;
                }).length;
              }

              onChangeRole(length
                  ? prevValue
                  : activeRole.length
                      ? activeRole[0].code
                      :  store.state.roleList[0].code );
            }
          }
        },{
          immediate: true
        })

        state.roleName = store.state.loginInfo.userInfo.displayName;
        state.account = store.state.loginInfo.userInfo.account;

        // 收到输入地址栏网址...
        if (
          store.state.loginInfo.userInfo.needRMCert &&
          store.state.certTrue == "false"
        ) {
          ElMessage.info("未进行实名认证");
          router.push("/idVerify");
        }
      }
    });

    return {
      ...toRefs(state),
      goSys,
      title,
      showHeadTitle,
      editPwd,
      logOut,
      closePwd,
      onChangeRole,
      store,
    };
  },
};
</script>

<style lang="less" scoped>
/* element-plus dialog 弹框头部标题样式 */
:deep .el-dialog__header {
  background-color: #409eff;
  padding-bottom: 15px;
}

:deep .el-dialog__header span {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 2px;
}

:deep .el-dialog__headerbtn .el-dialog__close {
  color: #fff;
  font-size: 22px;
}

:deep .el-dialog__headerbtn .el-dialog__close:hover {
  color: #333;
}

.about-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // 头部
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 77px;
    color: #406fe5;
    background-color: #fff;
    z-index: 99;
    .hy {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0 125px;
      height: 100%;
      background-color: #fff;

      h1.hylogo {
        font-family:STKaiti, SimHei;
        color: #406fe5;
        position: relative;
        margin: 0;
        flex: 1;
        text-align: left;

        .title-fs {
          padding-left: 95px;
          font-size: 38px;
        }
      }

      .toggleBtn {
        position: absolute;
        left: 0;
        top: 4px;

        .toggleIcon {
          margin: 0 5px 0 0;
          vertical-align: bottom;
        }
      }

      .titleSelect {
        padding-left: 95px;

        :deep(.el-input) {
          border: 0;
          padding-bottom: 0;
          position: relative;

          .el-input__inner {
            border: 0;
            padding-left: 0 !important;
            visibility: hidden;
          }

          .el-input__suffix {
            display: none;
          }
        }

        :deep( .el-input__prefix){

          position: absolute;
          top: 0;
          left: 0;
          box-sizing: border-box;
          white-space: nowrap;
          height: 40px;
          line-height: 40px;
          font-family: STKaiti, SimHei;
          color: #406fe5;
          font-size: 38px;
          font-weight: bold;

        }
      }







      .btn-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 88px;
        height: 100%;
        img {
          width: 30px;
          cursor: pointer;
          &:hover {
            filter: drop-shadow(6px 6px 5px rgba(64, 110, 230, 0.5));
          }
        }
      }
    }
  }

  // 轮播图
  :deep .block {
    width: 100%;
    // height: 592px;
    height: 70%;
    margin-top: 47px;
    box-sizing: border-box;
    .el-carousel {
      height: 100%;
    }
    .el-carousel__container {
      height: 100%;
    }
    .el-carousel__arrow {
      width: 75px;
      height: 75px;
      font-size: 34px;
    }
  }

  // 系统选择模块
  .main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    width: 1508px;
    height: 50%;
    box-sizing: border-box;
    z-index: 999;

    .info {
      height: 35px;
      line-height: 35px;
      margin: 0;
      color: #e6dd17;
      font-size: 22px;
      font-weight: 600;
      .roleName {
        color: #e6dd17;
      }
    }
    .card-carousel {
      height: calc(100% - 35px);
      box-sizing: border-box;
      .el-carousel {
        height: 100%;
      }
      :deep .el-carousel__container {
        height: 100% !important;
      }
      :deep .el-carousel__arrow {
        width: 60px;
        height: 60px;
        font-size: 36px;
        top: 255px;
        color: #406ee6;
        background: transparent;
        border-radius: 50%;
        box-sizing: border-box;
        border: 5px solid #406ee6;
        &:hover {
          border-color: #4b648c;
          i {
            color: #4b648c;
          }
        }
        &.el-carousel__arrow--left {
          left: 140px;
        }
        &.el-carousel__arrow--right {
          right: 140px;
        }

        i {
          font-weight: 600;
          color: #406ee6;
        }
      }
      // // 轮播组卡片样式
      .card-container {
        margin-top: 55px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        .item-box {
          position: relative;
          width: 205px;
          // width: 14%;
          height: 280px;
          margin: 0 20px;
          box-sizing: border-box;
          cursor: pointer;
          span.num-sort {
            position: absolute;
            top: -32px;
            left: 10px;
            color: #fff;
            font-size: 66px;
            font-weight: 600;
            z-index: 9999;
          }
          .el-image {
            width: 205px;
            height: 280px;
          }
          h4.title {
            position: relative;
            bottom: 50px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            margin: 0;
            font-size: 20px;
            color: #666;
            font-weight: 400;
            letter-spacing: 2px;
            &.hover-sty {
              bottom: 50%;
              color: #ffffff;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
</style>
